import React from "react";
import styles from "./modules/Footer.module.scss";

function Footer() {
  const currentYear = new Date().getFullYear();

  const phoneNumber = "+905541817459"; // Replace with your actual phone number.
  const message = "Hello! I would like to get in touch.";

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.leftSection}>
          <div className={styles.column}>
            <h4 className={styles.columnTitle}>NAM Products</h4>
            <ul>
              <li>
                <a href="/">Home Page</a>
              </li>
              <li>
                <a href="/our-products">Our Products</a>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <h4 className={styles.columnTitle}>The World of NAM</h4>
            <ul>
              <li>
                <a href="/our-materials">Materials</a>
              </li>
              <li>
                <a href="/for-the-hospitality-business">
                  For the hospitality business
                </a>
              </li>
              <li>
                <a href="/for-medical-uses">For medical uses</a>
              </li>
              <li>
                <a href="/for-special-care">For special care</a>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <div
              className={styles.columnTitle}
              style={{ marginBottom: 52 }}
            ></div>
            <ul>
              <li>
                <a href="/about-nam">About NAM</a>
              </li>
              <li>
                <a href="/contact-us">Contact us</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.rightSection}>
          <img
            src="/images/nam-footer.svg"
            alt="NAM Logo"
            className={styles.logo}
            loading="lazy"
          />
          <div className={styles.whatsapp}>
            <a
              href={whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-link"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
                style={{ width: "60px", height: "60px" }} // You can adjust the size of the icon.
              />
            </a>
          </div>
        </div>
      </div>

      <div className={styles.footerBottom}>
        <p>NAM House of Sleep {currentYear}&copy;</p>
        <a href="#top" className={styles.backToTop}>
          <div className={styles.backToTopIconContainer}>
            <img
              src="/images/up-arrow.svg"
              alt="Back to top"
              className={styles.backToTopIcon}
              loading="lazy"
            />
          </div>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
