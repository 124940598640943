import React from "react";
import styles from "./modules/HomePage.module.scss"; // Assuming you're using SCSS for styling
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/our-materials");
  };

  const handleFamiliesRedirect = () => {
    navigate("/product-category/mattresses/");
  };

  const handleHotelsRedirect = () => {
    navigate("/for-the-hospitality-business/");
  };

  const handleDoctorsRedirect = () => {
    navigate("/for-medical-uses/");
  };

  const handleSpecialCareRedirect = () => {
    navigate("/for-special-care/");
  };

  return (
    <div className={styles.homePage}>
      {/* First Block */}
      <div className={styles.firstBlock}>
        <div className={styles.leftContent}>
          <h1>Welcome to NAM, house of sleep</h1>
          <p className={styles.description}>
            Step into NAM, you can now sleep soundly
          </p>

          <div className={styles.mobileImageFirst}>
            <img
              src="/images/hero-pillow.png"
              alt="NAM House of Sleep"
              className={styles.bigImage}
            />
          </div>

          <div className={styles.iconColumns}>
            <div className={styles.column}>
              <div className={styles.item}>
                <img
                  src="/images/Mattress.svg"
                  alt="Icon 1"
                  className={styles.icon}
                />
                <span>Mattresses</span>
              </div>
              <div className={styles.item}>
                <img
                  src="/images/Pillows.svg"
                  alt="Icon 2"
                  className={styles.icon}
                />
                <span>
                  Pillows, pillow <br />
                  cases & sheets
                </span>
              </div>
              <div className={styles.item}>
                <img
                  src="/images/Sofa.svg"
                  alt="Icon 3"
                  className={styles.icon}
                />
                <span>
                  Sofas, sofa beds <br />& space divans
                </span>
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.item}>
                <img
                  src="/images/Bedsheets.svg"
                  alt="Icon 4"
                  className={styles.icon}
                />
                <span>
                  Bedsheets & <br />
                  protectors
                </span>
              </div>
              <div className={styles.item}>
                <img
                  src="/images/Beds.svg"
                  alt="Icon 5"
                  className={styles.icon}
                />
                <span>Beds</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rightContent}>
          <img
            src="/images/hero-pillow.png"
            alt="NAM House of Sleep"
            className={styles.bigImage}
          />
        </div>
      </div>

      {/* Second Block */}
      <div className={styles.secondBlock}>
        <div className={styles.leftContent}>
          <img
            src="/images/mattress-package.png"
            alt="Mattress Package"
            className={styles.mattressImage}
            loading="lazy"
          />
        </div>

        <div className={styles.rightContent}>
          <img
            src="/images/ressort.svg"
            alt="Ressort"
            className={styles.ressortIcon}
            loading="lazy"
          />
          <h2>At the service of your comfort</h2>
          <p className={styles.description}>
            Our comfort experts are here to understand your sleep and comfort
            needs and make sure they are met. Whether you are starting a family,
            opening a hotel, or simply furnishing your vacation home, NAM will
            help you find the perfect fit for your space, body, age, condition,
            and budget.
          </p>
          <img
            src="/images/mobile-mattress-package.png"
            alt="Mattress Package"
            className={styles.mobileMattressImage}
            loading="lazy"
          />
          <button className={styles.materialsButton} onClick={handleRedirect}>
            Our materials
          </button>
        </div>

        <img
          src="/images/background-1.svg"
          alt="Background"
          className={styles.backgroundImage}
          loading="lazy"
        />
      </div>

      {/* Third Block */}
      <div className={styles.thirdBlock}>
        <h2>One store for all</h2>
        <img
          src="/images/ressort-long.svg"
          alt="Ressort Long"
          className={styles.ressortLong}
        />

        <div className={styles.content}>
          <div className={styles.leftContent}>
            <p className={styles.description}>
              The recipe for a good night of sleep is a careful mix of delicate
              ingredients. A perfect mattress paired with the right pillow
              covered in soft sheets is just the beginning. Discover a world of
              textures, materials, and colors carefully chosen by our experts
              and made in Lebanon.
            </p>
            <button className={styles.productsButton}>See our products</button>
          </div>

          <div className={styles.rightContent}>
            <img
              src="/images/mattress-package-2.png"
              alt="Mattress Package"
              className={styles.mattressImage}
            />
          </div>
        </div>

        <img
          src="/images/background-2.svg"
          alt="Background"
          className={styles.backgroundImage}
        />
      </div>

      {/* Fourth Block */}
      <div className={styles.fourthBlock}>
        <img
          src="/images/ressort-long.svg"
          alt="Ressort Long"
          className={styles.ressortLong}
        />

        <div className={styles.cardContainer}>
          <div className={styles.cardRow}>
            <div className={styles.card}>
              <img
                src="/images/families.jpg"
                alt="families"
                className={styles.cardImage}
              />
              <h3>Families</h3>
              <p className={styles.cardDescription}>
                The room you and your loved ones deserve
              </p>
              <button
                className={styles.cardButton}
                onClick={handleFamiliesRedirect}
              >
                Discover
              </button>
            </div>
            <div className={styles.card}>
              <img
                src="/images/hotels.jpg"
                alt="hotels"
                className={styles.cardImage}
              />
              <h3>Hotels</h3>
              <p className={styles.cardDescription}>
                A level of comfort your clients haven’t heard of yet
              </p>
              <button
                className={styles.cardButton}
                onClick={handleHotelsRedirect}
              >
                Discover
              </button>
            </div>
          </div>

          <div className={styles.cardRow}>
            <div className={styles.card}>
              <img
                src="/images/doctors.jpg"
                alt="doctors"
                className={styles.cardImage}
              />
              <h3>Doctors</h3>
              <p className={styles.cardDescription}>
                Your patients deserve to rest
              </p>
              <button
                className={styles.cardButton}
                onClick={handleDoctorsRedirect}
              >
                Discover
              </button>
            </div>
            <div className={styles.card}>
              <img
                src="/images/special-care.jpg"
                alt="special-care"
                className={styles.cardImage}
              />
              <h3>Special care</h3>
              <p className={styles.cardDescription}>
                For those who need a little extra care
              </p>
              <button
                className={styles.cardButton}
                onClick={handleSpecialCareRedirect}
              >
                Discover
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Fifth Block */}
      <div className={styles.fifthBlock}>
        <h2>High-quality materials for prolonged comfort</h2>
        <p className={styles.description}>
          We make our product with premium material, from pocketed springs to
          our very own Biolatex technology. That way, we ensure that your
          investment in your health is a long-lasting one.
        </p>
        <button className={styles.materialsButton} onClick={handleRedirect}>
          Our materials
        </button>

        <div className={styles.imageContainer}>
          <img
            src="/images/mattress-package-3.png"
            alt="Mattress Package 3"
            className={`${styles.mattressImage} ${styles.desktopImage}`}
            loading="lazy"
          />
          <img
            src="/images/mobile-mattress-package-3.png"
            alt="Mobile Mattress Package 3"
            className={`${styles.mattressImage} ${styles.mobileImage}`}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
