// src/components/BackButton.js
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./modules/BackButton.module.scss"; // Import SCSS module

const BackButton = ({ to = "/admin" }) => {
  const navigate = useNavigate(); // For navigation

  return (
    <button className={styles.backButton} onClick={() => navigate(to)}>
      Back
    </button>
  );
};

export default BackButton;
