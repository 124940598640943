import React, { useState, useEffect } from "react";
import apiClient from "../../apiClient";
import imageCompression from "browser-image-compression"; // Import compression library
import { useNavigate, useParams } from "react-router-dom";
import Logout from "./Logout";
import BackButton from "./BackButton";
import styles from "./modules/AddProduct.module.scss";

const EditProduct = () => {
  const { productId } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [primaryImageUrl, setPrimaryImageUrl] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState([
    { description: "", value: "" },
  ]);
  const [secondaryImages, setSecondaryImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductAndCategories = async () => {
      try {
        const productRes = await apiClient.get(`/admin/products/${productId}`);
        const product = productRes.data;

        setName(product.name);
        setDescription(product.description);
        setPrimaryImageUrl(product.primary_picture);
        setCategoryId(product.category_id);
        setAdditionalInfo(
          product.additional_info || [{ description: "", value: "" }]
        );
        setSecondaryImages(product.secondary_images || []);

        const categoriesRes = await apiClient.get("/admin/categories");
        setCategories(categoriesRes.data);
      } catch (err) {
        console.error("Error fetching product and categories:", err);
      }
    };

    fetchProductAndCategories();
  }, [productId]);

  // Compress and upload the primary image to S3
  const handlePrimaryImageUpload = async (e) => {
    const file = e.target.files[0];

    const options = { maxSizeMB: 1, maxWidthOrHeight: 800, useWebWorker: true };

    try {
      const compressedFile = await imageCompression(file, options);

      const formData = new FormData();
      formData.append("image", compressedFile);

      const res = await apiClient.post("/admin/upload-image", formData);
      setPrimaryImageUrl(res.data.imageUrl); // Update the URL after upload
    } catch (err) {
      console.error("Error uploading compressed primary image:", err);
    }
  };

  // Compress and upload secondary images to S3
  const handleSecondaryImageUpload = async (e, index) => {
    const file = e.target.files[0];
    const options = { maxSizeMB: 1, maxWidthOrHeight: 800, useWebWorker: true };

    try {
      const compressedFile = await imageCompression(file, options);

      const formData = new FormData();
      formData.append("image", compressedFile);

      const res = await apiClient.post("/admin/upload-image", formData);

      const newSecondaryImages = [...secondaryImages];
      newSecondaryImages[index].image_url = res.data.imageUrl;
      setSecondaryImages(newSecondaryImages);
    } catch (err) {
      console.error("Error uploading compressed secondary image:", err);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const productData = {
        name,
        description,
        primary_picture: primaryImageUrl,
        category_id: categoryId,
        additional_info: additionalInfo,
        secondary_images: secondaryImages,
      };

      await apiClient.put(`/admin/products/${productId}`, productData);
      navigate("/admin/products");
    } catch (err) {
      console.error("Error updating product:", err);
    }
  };

  // Handle dynamic fields for Additional Info
  const handleAddAdditionalInfo = () => {
    setAdditionalInfo([...additionalInfo, { description: "", value: "" }]);
  };

  const handleRemoveAdditionalInfo = (index) => {
    const newAdditionalInfo = additionalInfo.filter((_, i) => i !== index);
    setAdditionalInfo(newAdditionalInfo);
  };

  const handleAdditionalInfoChange = (index, field, value) => {
    const newAdditionalInfo = [...additionalInfo];
    newAdditionalInfo[index][field] = value;
    setAdditionalInfo(newAdditionalInfo);
  };

  // Remove secondary image
  const handleRemoveSecondaryImage = (index) => {
    const newSecondaryImages = [...secondaryImages];
    newSecondaryImages[index].image_url = ""; // Clear the image URL
    setSecondaryImages(newSecondaryImages);
  };

  // Handle dynamic fields for Secondary Images
  const handleAddSecondaryImage = () => {
    setSecondaryImages([...secondaryImages, { image_url: "" }]);
  };

  return (
    <div className={styles.addProductContainer}>
      <h1 className={styles.title}>Edit Product</h1>
      <Logout />
      <BackButton to="/admin/products" />

      <form onSubmit={handleSubmit} className={styles.form}>
        <label>Product Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <label>Product Description</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        ></textarea>

        <label>Primary Picture</label>
        <input type="file" onChange={handlePrimaryImageUpload} />
        {primaryImageUrl && (
          <div className={styles.imageContainer}>
            <img
              src={primaryImageUrl}
              alt="Primary"
              className={styles.imagePreview}
            />
            <button
              type="button"
              className={styles.removeButton}
              onClick={() => setPrimaryImageUrl("")}
            >
              Remove
            </button>
          </div>
        )}

        <label>Category</label>
        <select
          value={String(categoryId)}
          onChange={(e) => setCategoryId(e.target.value)}
          required
        >
          <option value="">Select a category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>

        <h2>Additional Information</h2>
        {additionalInfo.map((info, index) => (
          <div key={index} className={styles.additionalInfoItem}>
            <input
              type="text"
              placeholder="Description"
              value={info.description}
              onChange={(e) =>
                handleAdditionalInfoChange(index, "description", e.target.value)
              }
              required
            />
            <input
              type="text"
              placeholder="Value"
              value={info.value}
              onChange={(e) =>
                handleAdditionalInfoChange(index, "value", e.target.value)
              }
              required
            />
            <button
              type="button"
              className={styles.removeButton}
              onClick={() => handleRemoveAdditionalInfo(index)}
            >
              Remove
            </button>
          </div>
        ))}

        <button type="button" onClick={handleAddAdditionalInfo}>
          Add More Info
        </button>

        <h2>Secondary Images</h2>
        {secondaryImages.map((image, index) => (
          <div key={index} className={styles.secondaryImageItem}>
            <input
              type="file"
              onChange={(e) => handleSecondaryImageUpload(e, index)}
            />
            {image.image_url && (
              <div className={styles.imageContainer}>
                <img
                  src={image.image_url}
                  alt={`Secondary ${index}`}
                  className={styles.imagePreview}
                />
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={() => handleRemoveSecondaryImage(index)}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        ))}

        <button type="button" onClick={handleAddSecondaryImage}>
          Add More Image
        </button>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default EditProduct;
