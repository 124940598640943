// src/apiClient.js
import axios from "axios";

const apiClient = axios.create({
  // baseURL: "http://localhost:5000/api",
  baseURL: "/api",
});

// Axios request interceptor to attach JWT token to every request
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwtToken"); // Get the token from localStorage

    if (token) {
      // If the token exists, add it to the Authorization header
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Interceptor to catch 401 errors (unauthorized)
apiClient.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Token expired or unauthorized, logging out...");
      localStorage.removeItem("jwtToken"); // Clear the token
      window.location.href = "/login"; // Redirect to login page
    }
    return Promise.reject(error); // Pass the error down
  }
);

export default apiClient;
