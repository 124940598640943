import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BreadcrumbMolecule from "../../molecules/BreadCrumb/BreadcrumbMolecule";
import ImageGallery from "../../organisms/ImageGallery/ImageGallery";
import ProductCard from "../../molecules/ProductCard/ProductCard";
import apiClient from "../../../apiClient";
import styles from "./modules/ProductPage.module.scss";

const ProductPage = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [secondaryImages, setSecondaryImages] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [activeTab, setActiveTab] = useState("description");
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const res = await apiClient.get(`/products/${productId}`);
        const productDetails = res.data;
        setProductData(productDetails);

        const categoryRes = await apiClient.get(
          `/categories/${productDetails.category_id}`
        );
        const categoryData = categoryRes.data;

        if (categoryData.parent_id === null) {
          setCategoryName(categoryData.name);
          setSubCategoryName("");
        } else {
          setSubCategoryName(categoryData.name);
          const parentCategoryRes = await apiClient.get(
            `/categories/${categoryData.parent_id}`
          );
          setCategoryName(parentCategoryRes.data.name);
        }

        const imagesRes = await apiClient.get(`/products/${productId}/images`);
        setSecondaryImages(imagesRes.data.map((img) => img.image_url));
      } catch (error) {
        console.error("Error fetching product or category data:", error);
        setError("Failed to load product details.");
      }
    };
    fetchProductData();
  }, [productId]);

  useEffect(() => {
    if (activeTab === "additional-info") {
      const fetchAdditionalInfo = async () => {
        try {
          const infoRes = await apiClient.get(
            `/products/${productId}/additional-info`
          );
          setAdditionalInfo(infoRes.data);
        } catch (error) {
          console.error("Error fetching additional information:", error);
        }
      };
      fetchAdditionalInfo();
    }
  }, [activeTab, productId]);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const relatedRes = await apiClient.get(
          `/products/${productId}/related`
        );
        setRelatedProducts(relatedRes.data);
      } catch (error) {
        console.error("Error fetching related products:", error);
      }
    };
    fetchRelatedProducts();
  }, [productId]);

  const renderAdditionalInfo = () =>
    additionalInfo.map((info, index) => (
      <React.Fragment key={index}>
        <div className={styles.infoRow}>
          <strong>{info.description.toUpperCase()}</strong>
          <span>{info.value}</span>
        </div>
        {index < additionalInfo.length - 1 && <hr />}
      </React.Fragment>
    ));

  if (error) return <div className={styles.errorMessage}>{error}</div>;
  if (!productData) return <div className={styles.loading}>Loading...</div>;

  return (
    <div className={styles.productPage}>
      <div className={styles.breadCrumbContainer}>
        <BreadcrumbMolecule
          category={categoryName}
          subCategory={subCategoryName}
          isProductPage={true}
        />
      </div>

      <div className={styles.mainContent}>
        <div className={styles.productContent}>
          <h2 className={styles.productName}>{productData.name}</h2>
          <ImageGallery
            mainImage={productData.primary_picture}
            secondaryImages={secondaryImages}
          />
        </div>
        <div className={styles.rightContent}>
          <div className={styles.tabs}>
            <button
              className={`${styles.tabButton} ${
                activeTab === "description" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("description")}
            >
              Description
            </button>
            <button
              className={`${styles.tabButton} ${
                activeTab === "additional-info" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("additional-info")}
            >
              Additional Information
            </button>
          </div>

          <div className={styles.tabContent}>
            {activeTab === "description" ? (
              <p>{productData.description}</p>
            ) : (
              renderAdditionalInfo()
            )}
          </div>
        </div>
      </div>

      <hr className={styles.separator} />
      <div className={styles.relatedProductsContainer}>
        <h2>Related Products</h2>
        <div className={styles.relatedProducts}>
          {relatedProducts.map((product) => (
            <ProductCard
              key={product.id}
              id={product.id}
              name={product.name}
              mainImage={product.primary_picture}
              width="24%"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
