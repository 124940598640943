export const slugify = (text) => {
  if (!text || typeof text !== "string") return ""; // Safeguard against null or undefined values

  return text
    .toLowerCase()
    .replace(/&/g, "and") // Replace & with "and"
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters (except hyphens)
    .replace(/\-\-+/g, "-") // Replace multiple hyphens with a single one
    .trim(); // Trim any remaining trailing or leading spaces
};
