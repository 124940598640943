import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./modules/BreadcrumbMolecule.module.scss";
import { deslugify } from "../../utils/desulgify";

const BreadcrumbMolecule = ({
  category,
  subCategory,
  resetSubCategory,
  isProductPage = false,
}) => {
  const navigate = useNavigate();

  // Handlers for navigation
  const handleHomeClick = () => {
    navigate("/"); // Redirect to homepage
  };

  const handleCategoryClick = () => {
    if (isProductPage) {
      // If on the ProductPage, navigate back to product listing with the selected category
      navigate(`/product-category/${category}`);
    } else {
      resetSubCategory(); // Reset subCategory in product listing
    }
  };

  const handleSubCategoryClick = () => {
    if (isProductPage && subCategory) {
      // Navigate to the product listing page with both category and subcategory filters
      navigate(`/product-category/${category}/${subCategory}`);
    }
  };

  return (
    <div className={styles.breadcrumbMolecule}>
      {/* Home link */}
      <span className={styles.breadcrumbItem} onClick={handleHomeClick}>
        Home
      </span>
      <span className={styles.breadcrumbSeparator}>/</span>

      {/* Category link */}
      <span
        className={
          subCategory ? styles.breadcrumbItem : styles.breadcrumbActive
        }
        onClick={handleCategoryClick}
        style={{ cursor: "pointer" }}
      >
        {deslugify(category)}
      </span>

      {/* SubCategory - only show if subCategory is selected */}
      {subCategory && (
        <>
          <span className={styles.breadcrumbSeparator}>/</span>
          <span
            className={styles.breadcrumbActive}
            onClick={isProductPage ? handleSubCategoryClick : undefined} // Only clickable on ProductPage
            style={isProductPage ? { cursor: "pointer" } : {}}
          >
            {deslugify(subCategory)}
          </span>
        </>
      )}
    </div>
  );
};

export default BreadcrumbMolecule;
