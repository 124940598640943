import React, { useState, useEffect } from "react";
import apiClient from "../../apiClient";
import styles from "./modules/AddEditSnippetPopup.module.scss";

const AddEditSnippetPopup = ({ snippet, onClose, onSave }) => {
  const [name, setName] = useState("");
  const [snippetCode, setSnippetCode] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (snippet) {
      // If editing, pre-fill the form with snippet data
      setName(snippet.name);
      setSnippetCode(snippet.snippet);
      setIsActive(snippet.is_active);
    } else {
      // If adding, reset the form fields
      setName("");
      setSnippetCode("");
      setIsActive(true);
    }
  }, [snippet]);

  const handleSave = async () => {
    // Basic validation: ensure all fields are filled
    if (!name || !snippetCode) {
      setError("Please fill out all fields.");
      return;
    }

    try {
      // Make sure is_active is passed as a number (1 for true, 0 for false)
      const snippetData = {
        name,
        snippet: snippetCode,
        is_active: isActive ? 1 : 0, // Ensure we pass 1 for active, 0 for inactive
      };

      if (snippet) {
        // Editing an existing snippet
        const res = await apiClient.put(
          `/admin/analytics-snippets/${snippet.id}`,
          snippetData
        );
        onSave(res.data); // Call onSave to update parent component
      } else {
        // Adding a new snippet
        const res = await apiClient.post(
          "/admin/analytics-snippets",
          snippetData
        );
        onSave(res.data); // Call onSave to update parent component
      }

      setError(""); // Clear error after successful save
      window.location.reload(); // Refresh the page to show updated info
    } catch (err) {
      console.error("Error saving snippet:", err);
      setError("Failed to save the snippet. Please try again.");
    }
  };

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContainer}>
        <h2>{snippet ? "Edit Snippet" : "Add Snippet"}</h2>

        {error && <p className={styles.errorMessage}>{error}</p>}

        <label>Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <label>Snippet Code</label>
        <textarea
          value={snippetCode}
          onChange={(e) => setSnippetCode(e.target.value)}
          required
        ></textarea>

        <label>
          <input
            type="checkbox"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
          Active
        </label>

        <div className={styles.buttonGroup}>
          <button onClick={handleSave} className={styles.saveButton}>
            {snippet ? "Save" : "Add"}
          </button>
          <button onClick={onClose} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEditSnippetPopup;
