// src/components/molecules/ProductSlider/ProductSlider.js
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import styles from "./modules/ProductSlider.module.scss";

const ProductSlider = ({ sliderTitle, products }) => {
  const navigate = useNavigate();

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`); // Navigate to the product page by ID
  };

  return (
    <div className={styles.sliderBlock}>
      <h2 className={styles.sliderTitle}>{sliderTitle}</h2>

      <Swiper
        spaceBetween={0}
        slidesPerView="auto"
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
        }}
        className={styles.swiper}
      >
        {products.map((product) => (
          <SwiperSlide key={product.id} className={styles.swiperSlide}>
            <div
              className={styles.sliderItem}
              onClick={() => handleProductClick(product.id)}
            >
              <img
                src={product.primary_picture} // Display main product image
                alt={product.name}
                className={styles.sliderImage}
              />
              <div className={styles.sliderItemTitle}>{product.name}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductSlider;
