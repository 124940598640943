import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../apiClient";
import Logout from "./Logout";
import BackButton from "./BackButton";
import styles from "./modules/CategoryList.module.scss"; // SCSS module

const CategoriesList = () => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]); // Filtered categories
  const [nameFilter, setNameFilter] = useState(""); // State for name filter
  const [error, setError] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [parentId, setParentId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await apiClient.get("/admin/categories");
        setCategories(Array.isArray(res.data) ? res.data : []);
        setFilteredCategories(Array.isArray(res.data) ? res.data : []); // Initial full list
      } catch (err) {
        console.error("Error fetching categories:", err);
        setError(
          "Failed to load categories. Please check your authentication."
        );
      }
    };
    fetchCategories();
  }, []);

  // Filter categories based on the name filter
  useEffect(() => {
    setFilteredCategories(
      categories.filter((category) =>
        category.name.toLowerCase().includes(nameFilter.toLowerCase())
      )
    );
  }, [nameFilter, categories]);

  const handleDelete = async () => {
    try {
      await apiClient.delete(`/admin/categories/${categoryToDelete}`);
      setCategories(categories.filter((cat) => cat.id !== categoryToDelete));
      setShowConfirmDelete(false);
      setCategoryToDelete(null);
    } catch (err) {
      console.error("Error deleting category:", err);
      setError("Failed to delete category");
    }
  };

  const handleEditClick = async (categoryId) => {
    try {
      const res = await apiClient.get(`/admin/categories/${categoryId}`);
      const category = res.data;
      const parentRes = await apiClient.get("/categories");
      setParentCategories(Array.isArray(parentRes.data) ? parentRes.data : []);
      setSelectedCategory(category);
      setEditCategoryName(category.name);
      setParentId(category.parent_id ? String(category.parent_id) : "");
      setShowEditModal(true);
    } catch (err) {
      console.error("Error fetching category data:", err);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiClient.put(`/admin/categories/${selectedCategory.id}`, {
        name: editCategoryName,
        parent_id: parentId || null,
      });
      setCategories(
        categories.map((cat) =>
          cat.id === selectedCategory.id
            ? { ...cat, name: editCategoryName, parent_id: parentId }
            : cat
        )
      );
      setShowEditModal(false);
    } catch (err) {
      console.error("Error updating category:", err);
      setError("Failed to update category");
    }
  };

  return (
    <div className={styles.categoriesListContainer}>
      <h1 className={styles.title}>Manage Categories</h1>
      <BackButton />
      <Logout />

      <button
        className={styles.addButton}
        onClick={() => navigate("/admin/categories/new")}
      >
        Add New Category
      </button>

      {/* Search Filter by Category Name */}
      <div className={styles.searchFilters}>
        <input
          type="text"
          placeholder="Search by Category Name"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          className={styles.searchInput}
        />
      </div>

      {error && <p className={styles.errorMessage}>{error}</p>}
      {filteredCategories.length === 0 ? (
        <p className={styles.noCategories}>No categories match the criteria.</p>
      ) : (
        <ul className={styles.categoryList}>
          {filteredCategories.map((category) => (
            <li key={category.id} className={styles.categoryItem}>
              <span className={styles.categoryName}>{category.name}</span>
              <div className={styles.categoryActions}>
                <button
                  className={styles.editButton}
                  onClick={() => handleEditClick(category.id)}
                >
                  Edit
                </button>
                <button
                  className={styles.deleteButton}
                  onClick={() => {
                    setShowConfirmDelete(true);
                    setCategoryToDelete(category.id);
                  }}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {showConfirmDelete && (
        <div className={styles.confirmDeleteOverlay}>
          <div className={styles.confirmDeletePopup}>
            <p>Are you sure you want to delete this category?</p>
            <button onClick={handleDelete} className={styles.confirmButton}>
              Yes, delete
            </button>
            <button
              onClick={() => setShowConfirmDelete(false)}
              className={styles.cancelButton}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {showEditModal && (
        <div className={styles.editModalOverlay}>
          <div className={styles.editModalPopup}>
            <h2>Edit Category</h2>
            <form onSubmit={handleEditSubmit}>
              <label>
                Category Name:
                <input
                  type="text"
                  value={editCategoryName}
                  onChange={(e) => setEditCategoryName(e.target.value)}
                  required
                />
              </label>

              <label>
                Parent Category (Optional):
                <select
                  value={parentId}
                  onChange={(e) => setParentId(e.target.value)}
                >
                  <option value="">None</option>
                  {parentCategories.map((cat) => (
                    <option key={cat.id} value={String(cat.id)}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </label>

              <button type="submit" className={styles.saveButton}>
                Save Changes
              </button>
              <button
                type="button"
                className={styles.cancelButton}
                onClick={() => setShowEditModal(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoriesList;
