import React from "react";
import ContactItem from "./ContactItem";
import styles from "./modules/ContactInfo.module.scss";

function ContactInfo({ fromBusinessPage = false }) {
  return (
    <div
      className={`${styles.contactInfo} ${
        fromBusinessPage ? styles.businessPage : ""
      }`}
    >
      <h1>Contact us</h1>
      <p>
        If you’re looking to order from home, know more about our products,
        learn about our business offers, or simply know when you can pass by,
        here is how you can reach us.
      </p>

      <div className={styles.contactItems}>
        <ContactItem iconSrc="/images/ContactUs/phone.svg" title="Phone">
          +961 3 284 149
        </ContactItem>
        <ContactItem iconSrc="/images/ContactUs/email.svg" title="Email">
          info@namhouseofsleep.com
        </ContactItem>
        <ContactItem iconSrc="/images/ContactUs/address.svg" title="Address">
          NAM SARL <br />
          Mkalles roundabout <br />
          Sin El Fil <br />
          Lebanon
        </ContactItem>
        <ContactItem
          iconSrc="/images/ContactUs/opening-hours.svg"
          title="Opening Hours"
        >
          Monday-Friday : <br />
          9:30am to 7pm <br />
          Saturday : <br />
          9:30 am to 3pm
        </ContactItem>
      </div>
    </div>
  );
}

export default ContactInfo;
