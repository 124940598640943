import React, { useState, useEffect } from "react";
import styles from "./modules/AddCategory.module.scss"; // SCSS Module for styling
import apiClient from "../../apiClient";
import { useNavigate } from "react-router-dom";
import BackButton from "./BackButton";

const AddCategory = () => {
  const [name, setName] = useState("");
  const [parentId, setParentId] = useState(null);
  const [parentCategories, setParentCategories] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  // Fetch existing categories to populate the Parent Category dropdown
  useEffect(() => {
    async function fetchCategories() {
      try {
        const res = await apiClient.get("/admin/categories");
        setParentCategories(res.data);
      } catch (err) {
        console.error("Error fetching categories:", err);
      }
    }

    fetchCategories();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send the form data to the server with the Authorization header
      await apiClient.post("/admin/categories", { name, parent_id: parentId });

      setSuccess("Category created successfully!");
      setError(""); // Clear any previous errors
    } catch (err) {
      console.error("Error creating category:", err);
      setError("Failed to create category. Please try again.");
      setSuccess(""); // Clear any success message
    }
  };

  return (
    <div className={styles.manageCategoriesContainer}>
      <h1 className={styles.title}>Add New Category</h1>

      {/* Back Button */}
      <BackButton to={"/admin/categories"} />

      <form className={styles.form} onSubmit={handleSubmit}>
        <label className={styles.label}>Category Name</label>
        <input
          type="text"
          className={styles.input}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <label className={styles.label}>Parent Category (Optional)</label>
        <select
          className={styles.select}
          value={parentId || ""}
          onChange={(e) => setParentId(e.target.value)}
        >
          <option value="">None</option>
          {parentCategories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>

        <button type="submit" className={styles.button}>
          Submit
        </button>

        {success && <p className={styles.successMessage}>{success}</p>}
        {error && <p className={styles.errorMessage}>{error}</p>}
      </form>
    </div>
  );
};

export default AddCategory;
