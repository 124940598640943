// src/components/organisms/ProductGrid/ProductGrid.js
import React, { useEffect, useState } from "react";
import styles from "./modules/ProductGrid.module.scss";
import ProductCard from "../../molecules/ProductCard/ProductCard";
import apiClient from "../../../apiClient";

const ProductGrid = ({
  selectedCategory,
  selectedSubCategory,
  setResultsCount,
}) => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const res = await apiClient.get("/products", {
          params: {
            category: selectedCategory,
            subcategory: selectedSubCategory,
          },
        });
        setProducts(res.data);
        setResultsCount(res.data.length);
        setError(null);
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to load products.");
        setResultsCount(0);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [selectedCategory, selectedSubCategory, setResultsCount]);

  if (loading) return <div className={styles.loading}>Loading products...</div>;
  if (error) return <div className={styles.errorMessage}>{error}</div>;

  return (
    <div className={styles.grid}>
      {products.length === 0 ? (
        <p>No products available in this category.</p>
      ) : (
        products.map((product) => (
          <ProductCard
            key={product.id}
            id={product.id} // Pass product ID
            name={product.name}
            mainImage={product.primary_picture}
          />
        ))
      )}
    </div>
  );
};

export default ProductGrid;
