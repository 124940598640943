import React, { useState, useEffect } from "react";
import apiClient from "../../apiClient";
import BackButton from "./BackButton";
import styles from "./modules/ManageAnalyticsScnippets.module.scss";
import AddEditSnippetPopup from "./AddEditSnippetPopup";

const ManageAnalyticsSnippets = () => {
  const [snippets, setSnippets] = useState([]);
  const [filteredSnippets, setFilteredSnippets] = useState([]); // Filtered snippets
  const [nameFilter, setNameFilter] = useState(""); // State for name filter
  const [statusFilter, setStatusFilter] = useState(""); // State for status filter
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [editingSnippet, setEditingSnippet] = useState(null);

  useEffect(() => {
    const fetchSnippets = async () => {
      try {
        const res = await apiClient.get("/admin/analytics-snippets");
        setSnippets(res.data);
        setFilteredSnippets(res.data); // Initialize full list for filtering
      } catch (err) {
        console.error("Error fetching snippets:", err);
        setError("Failed to load snippets.");
      }
    };
    fetchSnippets();
  }, []);

  // Filter snippets by name and status
  useEffect(() => {
    setFilteredSnippets(
      snippets.filter(
        (snippet) =>
          snippet.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
          (statusFilter === "" || // No filter, include all
            (statusFilter === "Active" && snippet.is_active) || // Active filter
            (statusFilter === "Inactive" && !snippet.is_active)) // Inactive filter
      )
    );
  }, [nameFilter, statusFilter, snippets]);

  const handleDelete = async (snippetId) => {
    try {
      await apiClient.delete(`/admin/analytics-snippets/${snippetId}`);
      setSnippets(snippets.filter((snippet) => snippet.id !== snippetId));
    } catch (err) {
      console.error("Error deleting snippet:", err);
      setError("Failed to delete snippet.");
    }
  };

  const handleAddClick = () => {
    setEditingSnippet(null);
    setShowPopup(true);
  };

  const handleEditClick = (snippet) => {
    setEditingSnippet(snippet);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSaveSnippet = (updatedSnippet) => {
    if (editingSnippet) {
      setSnippets(
        snippets.map((snippet) =>
          snippet.id === updatedSnippet.id ? updatedSnippet : snippet
        )
      );
    } else {
      setSnippets([...snippets, updatedSnippet]);
    }
    handleClosePopup();
  };

  return (
    <div className={styles.manageSnippetsContainer}>
      <h1 className={styles.title}>Manage Analytics Snippets</h1>

      <BackButton to="/admin" />

      <button className={styles.addButton} onClick={handleAddClick}>
        Add Analytics Snippet
      </button>

      {error && <p className={styles.errorMessage}>{error}</p>}

      {/* Search Filters */}
      <div className={styles.searchFilters}>
        <input
          type="text"
          placeholder="Search by Name"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          className={styles.searchInput}
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className={styles.searchInput}
        >
          <option value="">All Statuses</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
      </div>

      {filteredSnippets.length === 0 ? (
        <p className={styles.noSnippets}>
          No analytics snippets match the criteria.
        </p>
      ) : (
        <ul className={styles.snippetsList}>
          {filteredSnippets.map((snippet) => (
            <li key={snippet.id} className={styles.snippetItem}>
              <span className={styles.snippetName}>{snippet.name}</span>
              <span className={styles.snippetStatus}>
                {snippet.is_active ? "Active" : "Inactive"}
              </span>
              <button
                className={styles.editButton}
                onClick={() => handleEditClick(snippet)}
              >
                Edit
              </button>
              <button
                className={styles.deleteButton}
                onClick={() => handleDelete(snippet.id)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}

      {showPopup && (
        <AddEditSnippetPopup
          snippet={editingSnippet}
          onClose={handleClosePopup}
          onSave={handleSaveSnippet}
        />
      )}
    </div>
  );
};

export default ManageAnalyticsSnippets;
