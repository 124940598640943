import React, { useEffect, useState } from "react";
import apiClient from "../../../apiClient"; // Assuming you're using apiClient for API calls
import { slugify } from "../../utils/slugify";
import styles from "./modules/CategoryListOrganism.module.scss";

const CategoryListOrganism = ({
  selectedCategory,
  selectedSubCategory,
  setCategory,
  setSubCategory,
}) => {
  const [categories, setCategories] = useState([]);

  // Fetch categories from the backend
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await apiClient.get("/categories"); // Fetch categories from API
        const fetchedCategories = res.data;

        // Organize categories into a format where each category contains its subcategories
        const categoryTree = fetchedCategories
          .filter((cat) => !cat.parent_id) // Parent categories
          .map((parent) => ({
            ...parent,
            subcategories: fetchedCategories.filter(
              (sub) => sub.parent_id === parent.id
            ), // Find subcategories by matching parent_id
          }));

        setCategories(categoryTree);
      } catch (err) {
        console.error("Error fetching categories:", err);
      }
    };

    fetchCategories();
  }, []);

  // Handle subcategory click and update the state + URL
  const handleSubCategoryClick = (categoryName, subCategoryName) => {
    setCategory(categoryName); // Keep the category selected
    setSubCategory(categoryName, subCategoryName); // Update subcategory
  };

  return (
    <div>
      <h3>Browse</h3>
      <div>
        {categories.map((category, index) => (
          <div key={index} className={styles.categoryContainer}>
            <div
              className={`${styles.categoryName} ${
                slugify(category.name) === selectedCategory
                  ? styles.selected
                  : ""
              }`}
              onClick={() => setCategory(category.name)}
            >
              {category.name}
            </div>

            {/* Show all subcategories by default */}
            {category.subcategories.length > 0 && (
              <div className={styles.subCategoryList}>
                {category.subcategories.map((sub, subIndex) => (
                  <div
                    key={subIndex}
                    className={`${styles.subCategoryItem} ${
                      slugify(sub.name) === selectedSubCategory
                        ? styles.selected
                        : ""
                    }`}
                    onClick={() =>
                      handleSubCategoryClick(category.name, sub.name)
                    }
                  >
                    {sub.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryListOrganism;
