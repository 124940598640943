import React from "react";
import styles from "./modules/Dashboard.module.scss"; // Import SCSS module
import Logout from "./Logout";

const AdminDashboard = () => {
  return (
    <div className={styles.dashboardContainer}>
      <h1 className={styles.dashboardTitle}>Admin Dashboard</h1>
      <Logout />
      <div className={styles.dashboardActions}>
        <a href="/admin/products" className={styles.dashboardLink}>
          Manage Products
        </a>
        <a href="/admin/categories" className={styles.dashboardLink}>
          Manage Categories
        </a>
        <a href="/admin/analytics-snippets" className={styles.dashboardLink}>
          Manage Analytics Snippets
        </a>
      </div>
    </div>
  );
};

export default AdminDashboard;
