import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logout from "./Logout";
import BackButton from "./BackButton";
import apiClient from "../../apiClient";
import styles from "./modules/ManageProducts.module.scss"; // Import SCSS for styling

const ManageProducts = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // For filtered products
  const [error, setError] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [nameFilter, setNameFilter] = useState(""); // State for "By Name" filter
  const [categoryFilter, setCategoryFilter] = useState(""); // State for "By Category" filter
  const navigate = useNavigate(); // For navigation

  // Fetch products from the backend on component mount
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await apiClient.get("/admin/products");
        setProducts(res.data);
        setFilteredProducts(res.data); // Set filtered products initially to all products
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to load products.");
      }
    };
    fetchProducts();
  }, []);

  // Handle delete confirmation
  const handleDelete = async () => {
    try {
      await apiClient.delete(`/admin/products/${productToDelete}`);
      const updatedProducts = products.filter(
        (product) => product.id !== productToDelete
      );
      setProducts(updatedProducts);
      setFilteredProducts(updatedProducts);
      setShowConfirmDelete(false);
      setProductToDelete(null);
    } catch (err) {
      console.error("Error deleting product:", err);
      setError("Failed to delete product.");
    }
  };

  // Trigger the confirmation popup before deleting
  const confirmDelete = (productId) => {
    setProductToDelete(productId);
    setShowConfirmDelete(true);
  };

  // Filter products based on name and category filters
  useEffect(() => {
    const filtered = products.filter(
      (product) =>
        product.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
        (categoryFilter
          ? product.category_name &&
            product.category_name
              .toLowerCase()
              .includes(categoryFilter.toLowerCase())
          : true)
    );
    setFilteredProducts(filtered);
  }, [nameFilter, categoryFilter, products]);

  return (
    <div className={styles.manageProductsContainer}>
      <h1 className={styles.title}>Manage Products</h1>

      <BackButton />
      <Logout />

      {/* Add New Product Button */}
      <button
        className={styles.addButton}
        onClick={() => navigate("/admin/products/new")}
      >
        Add New Product
      </button>

      {/* Search Filters */}
      <div className={styles.searchFilters}>
        <input
          type="text"
          placeholder="Search by Name"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          className={styles.searchInput}
        />
        <input
          type="text"
          placeholder="Search by Category"
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
          className={styles.searchInput}
        />
      </div>

      {/* Display Error */}
      {error && <p className={styles.errorMessage}>{error}</p>}

      {/* Products List */}
      {filteredProducts.length === 0 ? (
        <p className={styles.noProducts}>No products available.</p>
      ) : (
        <ul className={styles.productsList}>
          {filteredProducts.map((product) => (
            <li key={product.id} className={styles.productItem}>
              <span className={styles.productName}>{product.name}</span>
              <span className={styles.productCategory}>
                {product.category_name || "No Category"}
              </span>
              <button
                className={styles.editButton}
                onClick={() => navigate(`/admin/products/edit/${product.id}`)}
              >
                Edit
              </button>
              <button
                className={styles.deleteButton}
                onClick={() => confirmDelete(product.id)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}

      {/* Confirmation popup for delete */}
      {showConfirmDelete && (
        <div className={styles.confirmDeleteOverlay}>
          <div className={styles.confirmDeletePopup}>
            <p>Are you sure you want to delete this product?</p>
            <button onClick={handleDelete} className={styles.confirmButton}>
              Yes, delete
            </button>
            <button
              onClick={() => setShowConfirmDelete(false)}
              className={styles.cancelButton}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageProducts;
