import React, { useState, useEffect } from "react";
import styles from "./modules/ImageGallery.module.scss";

const ImageGallery = ({ mainImage, secondaryImages = [] }) => {
  const [selectedImage, setSelectedImage] = useState(mainImage);
  const [isExpanded, setIsExpanded] = useState(false);

  // Update selected image whenever mainImage changes
  useEffect(() => {
    setSelectedImage(mainImage);
  }, [mainImage]);

  // Toggle expanded view
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.imageGallery}>
      {/* Main Image Container */}
      <div className={styles.mainImageContainer} onClick={handleToggleExpand}>
        <img
          src={selectedImage}
          alt="Main product"
          className={styles.mainImage}
        />
      </div>

      {/* Secondary Images */}
      <div className={styles.thumbnailContainer}>
        {[mainImage, ...secondaryImages].map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            className={`${styles.thumbnail} ${
              selectedImage === image ? styles.active : ""
            }`}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>

      {/* Expanded Image Overlay */}
      {isExpanded && (
        <div className={styles.expandedOverlay}>
          <button className={styles.closeButton} onClick={handleToggleExpand}>
            &times;
          </button>
          <img
            src={selectedImage}
            alt="Expanded product"
            className={styles.expandedImage}
          />
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
