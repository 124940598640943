import React from "react";
import styles from "./modules/Hospitality.module.scss"; // Import SCSS for styling
import ContactUsBlock from "../../../molecules/ContactUs/ContactUsBlock"; // Import ContactUsBlock component
import { useNavigate } from "react-router-dom";

function Hospitality() {
  const navigate = useNavigate();

  const handleCollectionsRedirect = () => {
    navigate("/product-category/mattresses/");
  };

  const handleBiolatexRedirect = () => {
    navigate("/our-materials");
  };

  return (
    <div className={styles.hospitalityPage}>
      {/* First Block */}
      <div className={styles.firstBlock}>
        <div className={styles.content}>
          <h1>
            A level of comfort as prestigious <br />
            as your guests
          </h1>
          <p>
            Sleep is a crucial part of your guests' experience in your
            establishment. Together we can make sure it is exceptional.
          </p>
        </div>
      </div>

      {/* Second Block */}
      <div className={styles.secondBlock}>
        {/* Left Image */}
        <div className={styles.leftImage}>
          <img
            src="/images/ForYourBusiness/Hospitality/pillow-love.png"
            alt="Pillow Love"
            loading="lazy"
          />
        </div>
        {/* Right Content */}
        <div className={styles.rightContent}>
          <h2>Made with love, built with science</h2>
          <div className={styles.mobileImage}>
            <img
              src="/images/ForYourBusiness/Hospitality/pillow-love.png"
              alt="Pillow Love"
              loading="lazy"
            />
          </div>
          <p>
            A great night of sleep is science, not magic. We use the best
            materials, alone or in combination to provide your guests with a
            unique, high-end sleep experience. We even work with you to
            determine which one would be the perfect fit for your establishment.
          </p>
        </div>
      </div>

      {/* Third Block */}
      <div className={styles.thirdBlock}>
        <h2>A mattress for every host</h2>
        <p>
          Nam will produce the mattresses, pillows, and sheets at the size of
          your choosing, whether standard or custom, in large quantities or just
          for a replacement part.
        </p>
        <button
          className={styles.collectionsButton}
          onClick={handleCollectionsRedirect}
        >
          Our collections
        </button>
      </div>

      {/* Fourth Block */}
      <div className={styles.fourthBlock}>
        <h2>Biolatex, the ultimate comfort</h2>
        <p>
          Offer your most esteemed guests the ultimate experience with
          mattresses and pillows made from our biolatex, a sustainable, natural,
          and supremely comfortable material.
        </p>
        <button
          className={styles.biolatexButton}
          onClick={handleBiolatexRedirect}
        >
          More on Biolatex
        </button>
      </div>

      <ContactUsBlock showBottomImage={false} showBackgroundImage={false} />
    </div>
  );
}

export default Hospitality;
