// src/components/organisms/ProductGrid/ProductGrid.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../../apiClient";
import styles from "./modules/ProductGrid.module.scss";

const mockImages = {
  Mattresses: "/images/ProductsPage/mattresses.jpg",
  "Pillows, pillow cases & sheets": "/images/ProductsPage/Pillow.jpg",
  "Bedsheets and protectors": "/images/ProductsPage/bedsheets.jpg",
  Beds: "/images/ProductsPage/beds.jpg",
};

const ProductGrid = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await apiClient.get("/categories/root");
        setCategories(res.data);
      } catch (err) {
        console.error("Error fetching categories:", err);
        setError("Failed to load categories.");
      }
    };

    fetchCategories();
  }, []);

  // Handle navigation with URL encoding for category names
  const handleProductClick = (categoryName) => {
    const encodedCategoryName = encodeURIComponent(categoryName);
    navigate(`/product-category/${encodedCategoryName}`);
  };

  if (error) {
    return <div className={styles.errorMessage}>{error}</div>;
  }

  return (
    <div className={styles.gridContainer}>
      {categories.map((category) => (
        <div
          key={category.id}
          className={styles.productBox}
          onClick={() => handleProductClick(category.name)}
        >
          <img
            src={
              mockImages[category.name] || "/images/ProductsPage/default.jpg"
            }
            alt={category.name}
            className={styles.image}
          />
          <div className={styles.label}>
            <p>{category.name}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductGrid;
