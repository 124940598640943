import React from "react";
import styles from "./modules/Medical.module.scss";
import ContactUsBlock from "../../../molecules/ContactUs/ContactUsBlock";
import { useNavigate } from "react-router-dom";

function Medical() {
  const navigate = useNavigate();

  const handleFoamRedirect = () => {
    navigate("/product-category/Mattresses/Memory%20Foam");
  };
  return (
    <div className={styles.medicalPage}>
      {/* First Block */}
      <div className={styles.firstBlock}>
        <h1>Your patients deserve the highest level of care</h1>
        <p>Because good sleep is an essential part of a healthy recovery.</p>
      </div>

      {/* Second Block */}
      <div className={styles.secondBlock}>
        {/* Left Image */}
        <div className={styles.leftImage}>
          <img
            src="/images/ForYourBusiness/Medical/medical-grade.jpg"
            alt="Medical Products"
            loading="lazy"
          />
        </div>

        {/* Right Content */}
        <div className={styles.rightContent}>
          <h2>Medical-grade products for hospitals</h2>
          <div className={styles.mobileImage}>
            <img
              src="/images/ForYourBusiness/Medical/medical-grade.jpg"
              alt="Medical Products"
              loading="lazy"
            />
          </div>
          <p>
            Let your patients rest in the comfort of a good mattress and pillow.
            With high-quality durable sleep equipment, you can ensure a
            comfortable recovery for those who need it.
          </p>
        </div>
      </div>

      {/* Third Block */}
      <div className={styles.thirdBlock}>
        {/* Left Content */}
        <div className={styles.leftContent}>
          <h2>
            Foam, the ideal material for <br />
            recovery
          </h2>
          <div className={styles.mobileImage}>
            <img
              src="/images/ForYourBusiness/Medical/foam.jpg"
              alt="Foam Products"
              loading="lazy"
            />
          </div>
          <p>
            From simple to triple layer, from low to high density, our foam
            mattresses will ensure your patients' comfort in your care. Suitable
            for electric beds, made in any size you need, hypoallergenic, fire
            retardant, they're all ready and waiting for you.
          </p>
          <button
            className={styles.discoverButton}
            onClick={handleFoamRedirect}
          >
            Discover our foam products
          </button>
        </div>

        {/* Right Image */}
        <div className={styles.rightImage}>
          <img
            src="/images/ForYourBusiness/Medical/foam.jpg"
            alt="Foam Products"
            loading="lazy"
          />
        </div>
      </div>

      <ContactUsBlock showBottomImage={false} showBackgroundImage={false} />
    </div>
  );
}

export default Medical;
